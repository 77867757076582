












import Vue from 'vue';
import ProductItem from '@/components/product/product-item.vue';
import { LOGISTIC, BOAT, TRANSPORT, SECURE, WORK, LUGGAGE, SHOWS, CARS_PHOTO, GLASS_PHOTO, MARKET_PHOTO } from '@/config/images';
import priceCalculator from '../service/price-calculator';
// import {WEBSITE_URL} from "@/constant/constants";

export default Vue.extend({
  name: 'HomeMain',
  components: {
    ProductItem,
  },
  data: () => ({
    products: [
      // {
      //   id: 1,
      //   name: 'Logistik Versicherung 4.0',
      //   description: 'Auswählen',
      //   image: LOGISTIC,
      // },
      // {
      //   id: 2,
      //   name: 'Boote & Yachten',
      //   description: 'Auswählen',
      //   image: BOAT,
      // },
      // {
      //   id: 3,
      //   name: 'Transport Versicherung 4.0',
      //   description: 'Auswählen',
      //   image: TRANSPORT,
      //   link: '/transport-insurance',
      //   key: 'OWN_GOODS_INSURANCE',
      // },
      // {
      //   id: 4,
      //   name: 'Reisegepäckversicherung',
      //   description: 'Auswählen',
      //   image: LUGGAGE,
      //   link: '/luggage-insurance',
      // },
      // {
      //   id: 5,
      //   name: 'Schaustellerversicherung',
      //   description: 'Auswählen',
      //   image: SHOWS,
      //   link: '/fairground-insurance',
      // },
      // {
      //   id: 6,
      //   name: 'Valorenversicherung',
      //   description: 'Auswählen',
      //   image: SECURE,
      //   link: '/asset-insurance',
      // },
      // {
      //   id: 7,
      //   name: 'Ausstellungsversicherung',
      //   description: 'Auswählen',
      //   image: CARS_PHOTO,
      //   link: '/exhibition-insurance',
      // },
      // {
      //   id: 8,
      //   name: 'Musterkollektions-Versicherung',
      //   description: 'Auswählen',
      //   image: GLASS_PHOTO,
      //   link: '/sample-collection-insurance',
      // },
      // {
      //   id: 9,
      //   name: 'Marktkonzept',
      //   description: 'Auswählen',
      //   image: MARKET_PHOTO,
      //   link: '/market-concept-insurance',
      // },
    ] as any,
  }),
  mounted() {
    this.$emit('onId', '');
    this.$emit('getCustomerData', '');
    this.getInsuranceForUser();
  },
  methods: {
    getInsuranceForUser() {
      let instanceArray = {
      };
      priceCalculator.getProducts().then((response) => {
        if (response.data.find((x: any) => x.name === 'LOGISTICS_INSURANCE')) {
          instanceArray = {
            id: 1,
            name: 'Logistik Versicherung 4.0',
            description: 'Auswählen',
            image: LOGISTIC,
            link: '/logistics-insurance',
            key: 'LOGISTICS_INSURANCE',
          };
          this.products.push(instanceArray);
        }
        // }
        // if (response.data.find((x: any) => x.name === 'NON')) {
        //   instanceArray = {
        //     id: 2,
        //     name: 'Boote & Yachten',
        //     description: 'Auswählen',
        //     image: BOAT,
        //   };
        //   this.products.push(instanceArray);
        // }
        if (response.data.find((x: any) => x.name === 'OWN_GOODS_INSURANCE')) {
          instanceArray = {
            id: 3,
            name: 'Transport Versicherung 4.0',
            description: 'Auswählen',
            image: TRANSPORT,
            link: '/transport-insurance',
            key: 'OWN_GOODS_INSURANCE',
          };
          this.products.push(instanceArray);
        }
        if (response.data.find((x: any) => x.name === 'ANNUAL_INSURANCE')) {
          instanceArray = {
            id: 4,
            name: 'Ausstellungsversicherung',
            description: 'Auswählen',
            image: CARS_PHOTO,
            link: '/exhibition-insurance',
          };
          this.products.push(instanceArray);
        }
        if (response.data.find((x: any) => x.name === 'VALOR_INSURANCE_COMPACT')) {
          instanceArray = {
            id: 5,
            name: 'Valorenversicherung',
            description: 'Auswählen',
            image: SECURE,
            link: '/asset-insurance',
          };
          this.products.push(instanceArray);
        }
        if (response.data.find((x: any) => x.name === 'SAMPLE_COLLECTION_INSURANCE')) {
          instanceArray = {
            id: 6,
            name: 'Musterkollektions-Versicherung',
            description: 'Auswählen',
            image: GLASS_PHOTO,
            link: '/sample-collection-insurance',
          };
          this.products.push(instanceArray);
        }
        if (response.data.find((x: any) => x.name === 'LUGGAGE_INSURANCE')) {
          instanceArray = {
            id: 7,
            name: 'Reisegepäckversicherung',
            description: 'Auswählen',
            image: LUGGAGE,
            link: '/luggage-insurance',
          };
          this.products.push(instanceArray);
        }
        if (response.data.find((x: any) => x.name === 'FAIRGROUND_INSURANCE')) {
          instanceArray = {
            id: 8,
            name: 'Schaustellerversicherung',
            description: 'Auswählen',
            image: SHOWS,
            link: '/fairground-insurance',
          };
          this.products.push(instanceArray);
        }
        if (response.data.find((x: any) => x.name === 'MARKET_CONCEPT_INSURANCE')) {
          instanceArray = {
            id: 9,
            name: 'Marktkonzept',
            description: 'Auswählen',
            image: MARKET_PHOTO,
            link: '/market-concept-insurance',
          };
          this.products.push(instanceArray);
        }
        // window.open(`${WEBSITE_PUBLIC_URL}files/pdf/${pdfName}`);
        // window.open(`${WEBSITE_PUBLIC_URL}files/pdf/${pdfName}`);
      });
    },
  },
});
