














import Vue from 'vue';

export default Vue.extend({
  name: 'product-item',
  computed: {
    getLink() {
      return (this.product as any).link ? (this.product as any).link : '/products';
    },
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
});
